import { CSSProperties, useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { PageContext } from '../../../contexts';
import { CloseIcon } from '../../../icons/icons';
import MobileTabletCallbackForm from '../../Form/MobileTabletCallbackForm/MobileTabletCallbackForm';
import styles from './MobileTabletCallback.module.css';

const MobileTabletCallback: React.FC = () => {
    const { t } = useTranslation()
    const { activeButton, setActiveButton } = useContext(PageContext)
    const [isSuccess, setIsSuccess] = useState(false)

    const closeButtonStyle: CSSProperties = {
        right: '3%',
        left: 'unset',
    }

    const handleClose = () => {
        setActiveButton('mobileMenu')
    }

    const handleCallHelp = () => {
        setActiveButton(t(`nav.help`)) // Reset the activeButton state
    }

    if (!isMobile) {
        return null
    }

    if (activeButton !== t(`nav.register`)) return null

    return (
        <div onClick={e => e.stopPropagation()} className={styles.container}>
            <button className={styles.closeButton} style={closeButtonStyle} onClick={handleClose}>
                <CloseIcon color='#fff' />
            </button>
            <div className={styles.header}>
                <span className={styles.title}>{t(`callback.request`)}</span>
            </div>
            <p className={styles.description}>{t(`callback.description`)}</p>
            <MobileTabletCallbackForm isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
            <div className={styles.bottomText}>{t(`callback.question`)}</div>
            <div style={{ textAlign: 'center' }}>
                <div className={styles.helpWrapper}>
                    {t(`callback.check`)}{' '}
                    <div className={styles.help} onClick={handleCallHelp}>
                        {t(`callback.help`)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileTabletCallback
