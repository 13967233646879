import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { amenitiesApi } from '../services/amenities';
import { parametersApi } from '../services/parameters';
import { propertiesApi } from '../services/properties';

export const store = configureStore({
    reducer: {
        [propertiesApi.reducerPath]: propertiesApi.reducer,
        [amenitiesApi.reducerPath]: amenitiesApi.reducer,
        [parametersApi.reducerPath]: parametersApi.reducer,
    },

    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(propertiesApi.middleware, amenitiesApi.middleware, parametersApi.middleware),
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
