import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DownloadIcon } from '../../../icons/icons'
import { formRequest } from '../../../utils/formRequest'
import styles from './MobileTabletBrochureForm.module.css'

const MobileTabletBrochureForm: React.FC<{ isSuccess: boolean; setIsSuccess: (isSuccess: boolean) => void }> = ({ isSuccess, setIsSuccess }) => {
    const { t } = useTranslation()
    const [formValues, setFormValues] = useState({
        firstName: { value: '', error: false },
        lastName: { value: '', error: false },
        email: { value: '', error: false },
    })

    // Fonction utilitaire pour valider les champs
    const validateField = (name: string, value: string) => {
        if (name === 'email') return /^[a-zA-Z0-9._%+-\u0600-\u06FF]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
        if (name === 'phone') return value.length > 4
        return value.trim().length > 0
    }

    const handleFormValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormValues(prev => ({
            ...prev,
            [name]: { value, error: !validateField(name, value) }, // validation immédiate lors de la saisie
        }))
    }

    const handleSubmitForm = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.preventDefault()

        const { firstName, lastName, email } = formValues
        let canSend = true

        // Valider tous les champs
        Object.keys(formValues).forEach(field => {
            const value = formValues[field as keyof typeof formValues].value
            if (!validateField(field, value)) {
                setFormValues(prev => ({
                    ...prev,
                    [field]: { ...prev[field as keyof typeof formValues], error: true },
                }))
                canSend = false
            }
        })

        if (!canSend) return

        formRequest([
            { label: 'first_name', value: firstName.value },
            { label: 'last_name', value: lastName.value },
            { label: 'email', value: email.value },
            { label: 'referrer', value: 'https://agre.speed.hebergement-com4design.fr/crm/download-brochure/' },
            { label: 'referrer_title', value: 'Download Brochure' },
            { label: 'form_id', value: '52c4b7b' },
            { label: 'queried_id', value: '781' },
            { label: 'post_id', value: '781' },
        ])
            .then(() => {
                setFormValues({
                    firstName: { value: '', error: false },
                    lastName: { value: '', error: false },
                    email: { value: '', error: false },
                })
                setIsSuccess(true)
            })
            .catch(() => {
                console.error('Error while submitting the form')
            })
    }

    return (
        <form className={styles.container}>
            {!isSuccess ? (
                <>
                    <div className={styles.tabletRow}>
                        <div>
                            <input
                                type='text'
                                className={styles.input}
                                placeholder={t(`brochure.firstname`)}
                                onChange={handleFormValueChange}
                                value={formValues.firstName.value}
                                name='firstName'
                            />
                            <div
                                className={styles.error}
                                style={{
                                    opacity: formValues.firstName.error ? 1 : 0,
                                    maxHeight: formValues.firstName.error ? '50px' : '0',
                                }}>
                                {formValues.firstName.error && <div className={styles.error}>{t(`callback.invalidFirstName`)}</div>}
                            </div>
                        </div>
                        <div>
                            <input
                                type='text'
                                className={styles.input}
                                placeholder={t(`brochure.lastname`)}
                                value={formValues.lastName.value}
                                onChange={handleFormValueChange}
                                name='lastName'
                            />
                            <div
                                className={styles.error}
                                style={{
                                    opacity: formValues.lastName.error ? 1 : 0,
                                    maxHeight: formValues.lastName.error ? '50px' : '0',
                                }}>
                                {formValues.lastName.error && <div className={styles.error}>{t(`callback.invalidLastName`)}</div>}
                            </div>
                        </div>
                    </div>
                    <div className={styles.tabletRow}>
                        <div>
                            <input
                                type='email'
                                className={styles.input}
                                placeholder={t(`brochure.email`)}
                                value={formValues.email.value}
                                onChange={handleFormValueChange}
                                name='email'
                            />
                            <div
                                className={styles.error}
                                style={{
                                    opacity: formValues.email.error ? 1 : 0,
                                    maxHeight: formValues.email.error ? '50px' : '0',
                                }}>
                                {formValues.email.error && <div className={styles.error}>{t(`callback.invalidEmail`)}</div>}
                            </div>
                        </div>
                    </div>

                    <button className={styles.formButton} onClick={handleSubmitForm}>
                        {t(`brochure.download`)}
                        <DownloadIcon color='#fff' />
                    </button>
                </>
            ) : (
                <div className={styles.successMessage}>
                    <p className={styles.successTitle}>Thank you for your interest in our brochure!</p>
                    <p className={styles.successText}>We will send you an email with the brochure shortly.</p>
                    <p className={styles.successSubText}>Thank you for your patience.</p>
                </div>
            )}
        </form>
    )
}

export default MobileTabletBrochureForm
