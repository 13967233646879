import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { WhatsappShareButton } from 'react-share'

import { PageContext } from '../../../contexts'
import { CloseIcon, CopyIcon, WhatsappIcon } from '../../../icons/icons'
import styles from './MobileTabletShareCard.module.css'

const MobileTabletShareCard: React.FC = () => {
    const { t } = useTranslation()
    const { setActiveButton } = useContext(PageContext)
    const containerRef = useRef<HTMLDivElement>(null)
    const [showTooltip, setShowTooltip] = useState(false)

    const copyToClipboard = async () => {
        const currentPage = window.location.href

        if (navigator.clipboard) {
            navigator.clipboard.writeText(currentPage).catch(err => {
                console.error('Could not copy text: ', err)
            })
        } else {
            const textarea = document.createElement('textarea')
            textarea.value = currentPage
            textarea.style.position = 'fixed'
            textarea.style.opacity = '0'
            document.body.appendChild(textarea)
            textarea.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.error('Fallback: Could not copy text', err)
            }
            document.body.removeChild(textarea)
        }
        setShowTooltip(true)

        setTimeout(() => {
            setShowTooltip(false)
        }, 2000)
    }

    const handleClose = useCallback(() => {
        setActiveButton('mobileMenu')
    }, [setActiveButton])

    if (!isMobile) {
        return null
    }
    return (
        <div onClick={e => e.stopPropagation()} className={styles.container} ref={containerRef}>
            <div className={styles.header}>
                <div>{t(`share.title`)}</div>
                <button className={styles.closeButton} onClick={handleClose}>
                    <CloseIcon color='#fff' />
                </button>
            </div>
            <div className={styles.buttonsContainer}>
                <WhatsappShareButton
                    resetButtonStyle={false}
                    className={styles.socialShareButton}
                    title='Agre Plateform, Discover Agre Plateform Interactive Map & 3D VR'
                    url={window.location.href}>
                    <WhatsappIcon />
                    <span>Whatsapp</span>
                </WhatsappShareButton>
                {showTooltip && <div className={styles.tooltip}>{t(`share.copied`)}</div>}
                <button className={styles.socialShareButton} onClick={copyToClipboard}>
                    <CopyIcon />
                    <span>{t(`share.copy`)}</span>
                </button>
            </div>
            <Link className={styles.copyright} to='http://www.virtualvisions.studio/' target='_blank' rel='noopener noreferrer'>
                {t(`copyright`)}
            </Link>
        </div>
    )
}

export default MobileTabletShareCard
