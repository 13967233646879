import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PageContext } from '../../../contexts';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { POB1LogoIcon } from '../../../icons/icons';
import BackButton from '../BackButton/BackButton';
import styles from './TopBar.module.css';

interface Props {
    isVRMode?: boolean
}

const TopBar: React.FC<Props> = ({ isVRMode }) => {
    const { pageTransitionTiming, setSubActiveButton } = useContext(PageContext)
    const isMobileView = useIsMobileView()
    const location = useLocation()
    const [displayedContent, setDisplayedContent] = useState<string | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const topbarTimeout = setTimeout(() => {
            setDisplayedContent(location.pathname)
        }, pageTransitionTiming)

        return () => clearTimeout(topbarTimeout)
    }, [location.pathname, pageTransitionTiming])

    const handleLogoClick = () => {
        navigate('/')
        setSubActiveButton('')
    }

    if (displayedContent === '/') {
        return null
    }

    return (
        <div className={styles.container}>
            {isMobileView && (
                <div onClick={handleLogoClick} className={styles.logo}>
                    <POB1LogoIcon />
                </div>
            )}
            {!isMobileView && <BackButton />}
        </div>
    )
}

export default TopBar
