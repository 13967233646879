import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PageContext } from '../../contexts'

export default function PageTransition() {
  const location = useLocation()
  const [isAnimating, setIsAnimating] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const { pageTransitionTiming } = useContext(PageContext)
  const [previousPath, setPreviousPath] = useState<string | null>(null)

  useEffect(() => {
    return () => {
      setPreviousPath(location.pathname)
    }
  }, [location.pathname])

  useEffect(() => {
    if (firstLoad) {
      setTimeout(() => setIsAnimating(false), pageTransitionTiming * 2)
      setFirstLoad(false)
    } else {
      setIsAnimating(true)
      setTimeout(() => setIsAnimating(false), pageTransitionTiming * 2)
    }
  }, [location, firstLoad, pageTransitionTiming])

  const transition = {
    type: 'tween',
    duration: pageTransitionTiming / 1000,
  }

  const opacityVariants = {
    initial: { opacity: firstLoad ? 1 : 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  return (
    <AnimatePresence mode="wait">
      {isAnimating && (
        <motion.div
          variants={opacityVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={transition}
          style={{
            position: 'fixed',
            background: 'black',
            width: '100vw',
            height: 'var(--vh)',
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        />
      )}
    </AnimatePresence>
  )
}
