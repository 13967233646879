import axios from 'axios';
import FormData from 'form-data';

export type FormFields = {
    label: string
    value: string | number
}[]

export const formRequest = async (formFields: FormFields) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData()

        // Ajouter chaque champ avec le préfixe "form_fields" directement dans formData
        formFields.forEach(field => {
            field.label !== 'referrer' &&
                field.label !== 'referrer_title' &&
                field.label !== 'queried_id' &&
                field.label !== 'post_id' &&
                field.label !== 'form_id' &&
                formData.append(`form_fields[${field.label}]`, field.value)
        })

        formData.append('action', 'elementor_pro_forms_send_form')
        formData.append('referrer', formFields.find(field => field.label === 'referrer')?.value as string)
        formData.append('referrer_title', formFields.find(field => field.label === 'referrer_title')?.value as string)
        formData.append('queried_id', formFields.find(field => field.label === 'queried_id')?.value as string)
        formData.append('post_id', formFields.find(field => field.label === 'post_id')?.value as string)
        formData.append('form_id', formFields.find(field => field.label === 'form_id')?.value as string)

        axios
            .post('https://agre.speed.hebergement-com4design.fr/crm/wp-admin/admin-ajax.php', formData, {
                headers: {
                    Accept: 'application/json, */*',
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}
