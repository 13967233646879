import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

// Function to switch to default fonts
function switchToDefaultFonts() {
  document.documentElement.style.setProperty('--font-bold', 'bold')
  document.documentElement.style.setProperty('--font-medium', 'medium')
  document.documentElement.style.setProperty('--font-semi-bold', 'semi-bold')
  document.documentElement.style.setProperty('--font-regular', 'regular')
  document.documentElement.style.setProperty('--font-thin', 'thin')
  document.documentElement.style.setProperty('--font-light', 'light')
  document.documentElement.removeAttribute('data-lang')
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    interpolation: {
      escapeValue: false,
    },
  })

// Listen for language changes
i18n.on('languageChanged', function (lng) {
  // if (lng === 'en') {
  //   switchToArabicFonts()
  // } else {
  //   switchToDefaultFonts()
  // }
  switchToDefaultFonts()
})

export default i18n
