import './style/reset.css';
import './style/fonts.css';
import 'react-phone-number-input/style.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { store } from './app/store';
import { PageProvider } from './contexts';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                    <PageProvider>
                        <App />
                    </PageProvider>
                </BrowserRouter>
            </I18nextProvider>
        </Provider>
    </React.StrictMode>
)

reportWebVitals()
