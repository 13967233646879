// https://agre.speed.hebergement-com4design.fr/crm/wp-json/options/all

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Parameters } from '../types/parameters';

export const parametersApi = createApi({
    reducerPath: 'parametersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://',
    }),
    endpoints: builder => ({
        getParameters: builder.query<Parameters, void>({
            query: () => 'agre.speed.hebergement-com4design.fr/crm/wp-json/options/all',
            transformResponse: async (response: any) => {
                return response
            },
        }),
    }),
})

export const { endpoints, useGetParametersQuery } = parametersApi
