import { CSSProperties, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

import { useIsMobileView } from '../../../hooks/useIsMobileView'
import styles from './CopyrightText.module.css'

const CopyrightText: React.FC = () => {
    const location = useLocation()
    const isMobileView = useIsMobileView()
    const containerStyle: CSSProperties & { [key: string]: any } = useMemo(() => {
        return {
            left: location.pathname === '/' ? '' : '2%',
            width: location.pathname === '/' ? '100%' : '20%',
            textAlign: location.pathname === '/' ? 'center' : 'left',
            justifyContent: location.pathname === '/' ? 'center' : 'flex-start',
        }
    }, [location])

    if ((isMobile && location.pathname === '/') || !isMobileView) {
        return (
            <a href='https://virtualvisions.studio/' target='_blank' rel='noopener noreferrer' className={styles.container} style={containerStyle}>
                <span>© 2024 POB1 by</span>
                <strong style={{ textDecoration: 'underline', display: 'inline-block', fontWeight: 'normal', marginLeft: 4 }}>VVS</strong>.
                <span>All rights reserved.</span>
            </a>
        )
    } else return null
}

export default CopyrightText
