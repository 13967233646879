// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset CSS */

/* Box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin and padding */
* {
  margin: 0;
  padding: 0;
}

/* Remove list styles */
ol,
ul {
  list-style: none;
}

/* Remove default anchor styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove default button styles */
button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/style/reset.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd,eAAe;AACf;;;EAGE,sBAAsB;AACxB;;AAEA,sCAAsC;AACtC;EACE,SAAS;EACT,UAAU;AACZ;;AAEA,uBAAuB;AACvB;;EAEE,gBAAgB;AAClB;;AAEA,iCAAiC;AACjC;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA,iCAAiC;AACjC;EACE,YAAY;EACZ,gBAAgB;EAChB,UAAU;EACV,eAAe;AACjB","sourcesContent":["/* Reset CSS */\n\n/* Box sizing */\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\n/* Remove default margin and padding */\n* {\n  margin: 0;\n  padding: 0;\n}\n\n/* Remove list styles */\nol,\nul {\n  list-style: none;\n}\n\n/* Remove default anchor styles */\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n/* Remove default button styles */\nbutton {\n  border: none;\n  background: none;\n  padding: 0;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
