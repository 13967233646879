import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { FloorPlate } from '../types/floorPlates'
import { Property, PropertyResponse } from '../types/property'
import { PropertyType } from '../types/propertyType'

export const propertiesApi = createApi({
    reducerPath: 'propertiesApi',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'https://dkkkow0.etherial.cloud',
        baseUrl: 'https://',
    }),

    endpoints: builder => ({
        // getProperties: builder.query<Property[], string>({
        //   query: (project) => `${project}/properties`,
        //   transformResponse: async (response: { data: Property[] }) => {
        //     return response.data;
        //   },
        // }),
        // getProperty: builder.query<Property, { project: string; id: number }>({
        //   query: ({ project, id }) => `${project}/properties/${id}`,
        //   transformResponse: async (response: { data: Property }) => {
        //     return response.data;
        //   },
        // }),
        // getStatus: builder.query({
        //   query: () => `status`,
        //   transformResponse: async (response: { data: Status[] }) => {
        //     return response.data;
        //   },
        // }),
        getProperty: builder.query<Property, number>({
            query: id => `agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/properties/${id}`,
            transformResponse: async (response: Property) => {
                return response
            },
        }),
        getProperties: builder.query<Property[], void>({
            query: () => 'agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/properties?per_page=500',
            transformResponse: async (response: PropertyResponse[]) => {
                return response.map(property => {
                    return {
                        id: property.id,
                        name: property.title.rendered,
                        status: property.status,
                        svg_building: property.acf.svg_building,
                        svg_path_building: property.acf.svg_path_building,
                        svg_path_building_color: property.acf.svg_path_building_color,

                        svg_path_floorplan: property.acf.svg_path_floorplan,
                        svg_path_floorplan_color: property.acf.svg_path_floorplan_color,

                        svg_measure_floorplan: property.acf.svg_measure_floorplan,

                        front_svg_path_building: property.acf.front_svg_path_building,
                        back_svg_path_building: property.acf.back_svg_path_building,

                        position_mesurements: property.acf.position_mesurements,

                        postion_left_building: property.acf.postion_left_building,
                        postion_top_building: property.acf.postion_top_building,
                        svg_unit: property.acf.svg_unit,
                        postion_left_unit: property.acf.postion_left_unit,
                        postion_top_unit: property.acf.postion_top_unit,
                        unit_number: property.acf.unit_number,
                        unit_floor: property.acf.unit_floor,
                        unit_type: property.acf.unit_type,
                        terrace: property.acf.terrace,
                        unit_status: property.acf.unit_status,
                        unit_area: property.acf.gsa,
                        unit_price: property.acf.unit_price,
                        unit_view: property.acf.unit_view,
                        'status-amenitie': property['status-amenitie'],
                        'type-amenitie': property['type-amenitie'],
                        'floor-plates': property['floor-plates'][0],
                        'type-properties': property['type-properties'],
                    }
                })
            },
        }),
        getFloorPlates: builder.query<FloorPlate[], void>({
            query: () => 'agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/floor-plates?per_page=100&order=desc',
            transformResponse: async (response: any[]) => {
                return response.map(floorPlate => {
                    return {
                        id: floorPlate.id,
                        name: floorPlate.name,
                        slug: floorPlate.slug,
                        image: floorPlate.acf.image,
                        front_svg_path: floorPlate.acf.front_svg_path,
                        back_svg_path: floorPlate.acf.back_svg_path,
                    }
                })
            },
        }),
        getFloorPlateById: builder.query<any, number>({
            query: id => `agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/floor-plates/${id}`,
            transformResponse: async (response: any) => {
                return response
            },
        }),
        getPropertyTypes: builder.query<PropertyType[], void>({
            query: () => 'agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/type-properties?per_page=100',
            transformResponse: async (response: any[]) => {
                return response.map(floorPlate => {
                    return {
                        id: floorPlate.id,
                        name: floorPlate.name,
                        slug: floorPlate.slug,
                        type_color: floorPlate.acf.type_color,
                        parent: floorPlate.parent,
                        order: Number(floorPlate.acf.order ?? ('0' as string)),
                        gallery: floorPlate.acf.gallery,

                        property_mesurements: floorPlate.acf.property_mesurements,
                    }
                })
            },
        }),
        getpropertyTypeById: builder.query<any, number>({
            query: id => `agre.speed.hebergement-com4design.fr/crm/wp-json/wp/v2/type-properties/${id}`,
            transformResponse: async (response: any) => {
                return response
            },
        }),
    }),
})

export const {
    useLazyGetPropertyQuery,
    useGetPropertiesQuery,
    useGetFloorPlatesQuery,
    useLazyGetFloorPlateByIdQuery,
    useGetPropertyTypesQuery,
    useLazyGetPropertyTypesQuery,
    useLazyGetpropertyTypeByIdQuery,
} = propertiesApi
