export enum Type {
    residential = 1,
    religious = 2,
    business = 3,
    troisSix = 4,
    retail = 5,
    education = 6,
    health = 7,
    transportation = 8,
    keyLandmarks = 9,
    entertainment = 10,
    clubhouse = 11,
    skylinepool = 12,
    wellnesscourt = 13,
    kidsplayarea = 14,
    garden = 15,
    zenlawn = 16,
    kidspool = 17,
    courtyardpool = 18,
    sunsetterrace = 19,
    pharmacies = 20,
    coffeePlaces = 21,
    groceryStores = 22,
    portfolio = 148,
    lobby = 150,
}

export type Amenity = {
    id: number
    name: string
    type: number | false
    icon: string
    position_left: string
    position_top: string
    localisation_data_distance: string
    localisation_data_duration: string
    'localisation_data_-_walking_duration': string
    info_popup_top: string
    info_popup_left: string
    line_icon: string
    line_icon_style_width: string
    line_icon_style_left: string
    line_icon_style_top: string
    reversed: string
    map_style: string
    image: string
    svg_data: string
    gallery: false | string[]

    popup_direction: 'Down' | 'Up' | false

    svg_map_circle: string
    svg_map_line_path: string
    svg_map_icon_path: string

    svg_z_position: number

    view_side: 'front' | 'back'

    pointposition__x: string
    pointposition__y: string
    joiningLinepath: string
    nameposition__x: string
    nameposition__y: string

    key_landmarks_icon: false | string
}

export type AmenityResponse = {
    id: number
    date: string
    date_gmt: string
    guid: {
        rendered: string
    }
    modified: string
    modified_gmt: string
    slug: string
    status: string
    type: string
    link: string
    title: {
        rendered: string
    }
    menu_order: number
    template: string
    format: string
    meta: {
        _acf_changed: boolean
    }
    featured_image_src: string
    'type-amenitie': Type[]
    class_list: string[]
    acf: Amenity & { '⁠joininglinepath': string }
    _links: {
        self: {
            href: string
        }[]
        collection: {
            href: string
        }[]
        about: {
            href: string
        }[]
        'wp:attachment': {
            href: string
        }[]
        curies: {
            name: string
            href: string
            templated: boolean
        }[]
    }
}
