import { useContext, useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { PageContext } from '../../../contexts'
import { CallbackIcon, DownloadIcon, ShareIcon } from '../../../icons/icons'
import { useGetParametersQuery } from '../../../services/parameters'
import styles from './MobileMenu.module.css'

const MobileMenu: React.FC = () => {
    const { t } = useTranslation()
    const { setActiveButton } = useContext(PageContext)
    const { data: parameters } = useGetParametersQuery()
    const menuRef = useRef<HTMLDivElement>(null)
    // Determine if the device is a tablet based on window width

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setActiveButton('')
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [menuRef, setActiveButton])

    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName)
    }

    if (!isMobile) {
        return null
    }

    return (
        <div className={styles.container} ref={menuRef}>
            <div className={styles.menuItemWrapper}>
                <div className={styles.menuItem} onClick={() => handleButtonClick(t(`nav.share`))}>
                    <ShareIcon width={16} height={16} />
                    <div className={styles.menuItemText}>{t(`nav.share`)}</div>
                </div>
                {parameters?.brochure_link && parameters?.brochure_link.length > 0 && (
                    <div className={styles.menuItem} onClick={() => handleButtonClick(t(`nav.brochure`))}>
                        <DownloadIcon width={16} height={16} color='#fff' />
                        <div className={styles.menuItemText}>{t(`nav.brochure`)}</div>
                    </div>
                )}
                <div className={styles.menuItem} onClick={() => handleButtonClick(t(`nav.register`))}>
                    <CallbackIcon width={16} height={16} color='#fff' />
                    <div className={styles.menuItemText}>{t(`nav.register`)}</div>
                </div>
            </div>
            <a className={styles.copyright} href='http://www.virtualvisions.studio/' target='_blank' rel='noopener noreferrer'>
                <span>© 2024 POB1 by</span>
                <strong style={{ textDecoration: 'underline', display: 'inline-block', fontWeight: 'normal', marginLeft: 4 }}>VVS</strong>.
                <span style={{ marginLeft: 4 }}>All rights reserved.</span>
            </a>
        </div>
    )
}

export default MobileMenu
