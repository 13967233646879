import { AnimatePresence, motion } from 'framer-motion'
import React, { Suspense, useContext, useEffect } from 'react'
import { useMobileOrientation } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useLocation, useRoutes } from 'react-router-dom'

import PageTransition from './components/PageTransition/PageTransition'
import CopyrightText from './components/Shared/CopyrightText/CopyrightText'
import Help from './components/Shared/Help/Help'
import MobileMenu from './components/Shared/MobileMenu/MobileMenu'
import MobileTabletBrochure from './components/Shared/MobileTabletBrochure/MobileTabletBrochure'
import MobileTabletCallback from './components/Shared/MobileTabletCallback/MobileTabletCallback'
import MobileTabletHelp from './components/Shared/MobileTabletHelp/MobileTabletHelp'
import MobileTabletRegister from './components/Shared/MobileTabletRegister/MobileTabletRegister'
import MobileTabletShareCard from './components/Shared/MobileTabletShareCard/MobileTabletShareCard'
import TopBar from './components/Shared/TopBar/TopBar'
import { PageContext } from './contexts'
import { useIsMobileView } from './hooks/useIsMobileView'
import { useGetParametersQuery } from './services/parameters'

const CityPage = React.lazy(() => import('./pages/CityPage/CityPage'))
const ResidencyPage = React.lazy(() => import('./pages/ResidencyPage/ResidencyPage'))
const HousePlanPage = React.lazy(() => import('./pages/HousePlanPage/HousePlanPage'))
const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'))
const ZoomedCityPage = React.lazy(() => import('./pages/ZoomedCityPage/ZoomedCityPage'))
const Page360 = React.lazy(() => import('./pages/Page360/Page360'))

function App() {
    const { t } = useTranslation()
    const { isVRMode, activeButton, pageTransitionTiming, setActiveButton } = useContext(PageContext)
    const { data: parameters } = useGetParametersQuery()
    const location = useLocation()
    const isMobileView = useIsMobileView()
    const { isLandscape } = useMobileOrientation()

    const element = useRoutes([
        { path: '/', element: <HomePage key='homePage' /> },
        { path: '/city', element: <CityPage key='cityPage' map={parameters?.map_style_dubai ?? ''} /> },
        { path: '/zoomed-city', element: <ZoomedCityPage key='zoomedPage' map={parameters?.map_style_silicon_oasis ?? ''} /> },
        {
            path: '/masterplan',
            element: (
                <ResidencyPage
                    key='residencyPage'
                    frontView={parameters?.map_style_serenova_front ?? ''}
                    backView={parameters?.map_style_serenova_back ?? ''}
                />
            ),
        },
        { path: '/page-360', element: <Page360 key='page360' /> },
        { path: '/house-plan', element: <HousePlanPage key='housePlanPage' /> },
        { path: '*', element: <div>Unknown Page</div> },
    ])

    useEffect(() => {
        import('./pages/HousePlanPage/HousePlanPage')

        for (let i = 0; i < 10; i++) {
            let src = `https://agre.speed.hebergement-com4design.fr/crm/wp-content/uploads/2024/10/${i}.webp`
            const newImage = new Image()
            newImage.src = src
            // @ts-ignore
            window[src] = newImage
        }
    }, [])

    const slideVariants = {
        hidden: {
            opacity: 0,
            x: '100%',
        },
        visible: {
            opacity: 1,
            x: 0,
        },
        exit: {
            opacity: 0,
            x: '100%',
        },
    }

    const slideUpVariants = {
        hidden: {
            opacity: 0,
            y: '100%',
        },
        visible: {
            opacity: 1,
            y: '0%',
        },
        exit: {
            opacity: 0,
            y: '100%',
        },
    }

    const opacityVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 },
    }

    const opacityTransition = {
        duration: (pageTransitionTiming / 1000) * 0.1,
        delay: (pageTransitionTiming / 1000) * 0.9,
        ease: 'easeOut',
    }

    useEffect(() => {
        const height = CSS.supports('height', '100dvh') ? '100dvh' : '100vh'
        document.documentElement.style.setProperty('--vh', height)
    }, [])

    if (isLandscape && isMobileView) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h1 style={{ textAlign: 'center', maxWidth: '70%' }}>Kindly rotate your device to portrait mode for the best experience.</h1>
            </div>
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            <AnimatePresence>
                {activeButton === t(`nav.share`) && isMobileView ? (
                    <motion.div
                        variants={slideUpVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        onClick={() => setActiveButton('mobileMenu')}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                        style={{
                            position: 'fixed',
                            bottom: '0%',
                            width: '100%',
                            height: '100%',
                            zIndex: 500,
                        }}>
                        <MobileTabletShareCard />
                    </motion.div>
                ) : null}
                {activeButton === t(`nav.callback`) && isMobileView ? (
                    <motion.div
                        variants={slideUpVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        onClick={() => setActiveButton('mobileMenu')}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                        style={{
                            position: 'fixed',
                            bottom: '0%',
                            width: '100%',
                            height: '100%',
                            zIndex: 500,
                        }}>
                        <MobileTabletCallback />
                    </motion.div>
                ) : null}
                {activeButton === t('nav.register') && isMobileView ? (
                    <motion.div
                        variants={slideUpVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        onClick={() => setActiveButton('mobileMenu')}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                        style={{
                            position: 'fixed',
                            bottom: '0%',
                            width: '100%',
                            height: '100%',
                            zIndex: 500,
                        }}>
                        <MobileTabletRegister />
                    </motion.div>
                ) : null}
                {activeButton === t('nav.brochure') && isMobileView ? (
                    <motion.div
                        variants={slideUpVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                        onClick={() => setActiveButton('mobileMenu')}
                        style={{
                            position: 'fixed',
                            bottom: '0%',
                            width: '100%',
                            height: '100%',
                            zIndex: 500,
                        }}>
                        <MobileTabletBrochure />
                    </motion.div>
                ) : null}
                {activeButton === t(`nav.help`) && !isMobileView ? (
                    <motion.div
                        variants={slideVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                        onClick={() => setActiveButton('mobileMenu')}
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            zIndex: 500,
                            width: '20%',
                            right: 'unset',
                            left: 'unset',
                            display: isMobileView ? 'none' : 'block',
                        }}>
                        <Help />
                    </motion.div>
                ) : null}
                {activeButton === t(`nav.help`) && isMobileView ? (
                    <motion.div
                        variants={slideUpVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                        onClick={() => setActiveButton('mobileMenu')}
                        style={{
                            position: 'fixed',
                            bottom: '0%',
                            width: '100%',
                            height: '100%',
                            zIndex: 500,
                        }}>
                        <MobileTabletHelp />
                    </motion.div>
                ) : null}
                {activeButton === 'mobileMenu' ? (
                    <motion.div
                        variants={slideUpVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                        }}>
                        <MobileMenu />
                    </motion.div>
                ) : null}
            </AnimatePresence>

            <AnimatePresence mode='wait'>
                <motion.div
                    key={`page-${location.key}`}
                    initial='hidden'
                    animate='visible'
                    exit='exit'
                    variants={opacityVariants}
                    transition={opacityTransition}
                    style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    backgroundColor: 'black',
                                }}
                            />
                        }>
                        <TopBar isVRMode={isVRMode} />
                        <div style={{ position: 'relative', width: '100%', height: 'var(--vh)' }}>{element}</div>
                    </Suspense>
                </motion.div>
            </AnimatePresence>

            <CopyrightText />

            <PageTransition />
        </div>
    )
}

export default App
