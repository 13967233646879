import { useContext, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { PageContext } from '../../../contexts'
import { CloseIcon } from '../../../icons/icons'
import MobileTabletBrochureForm from '../../Form/MobileTabletBrochureForm/MobileTabletBrochureForm'
import styles from './MobileTabletBrochure.module.css'

const MobileTabletBrochure: React.FC = () => {
    const { t } = useTranslation()
    const { setActiveButton } = useContext(PageContext)
    const brochureRef = useRef<HTMLDivElement>(null)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleClose = () => {
        setActiveButton('mobileMenu')
        setIsSuccess(false)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (brochureRef.current && !brochureRef.current.contains(event.target as Node)) {
                handleClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [brochureRef])

    if (!isMobile) {
        return null
    }

    return (
        <div onClick={e => e.stopPropagation()} className={styles.container}>
            <div className={styles.header}>
                <span className={styles.title}>{t(`brochure.title`)}</span>
                <button className={styles.closeButton} onClick={handleClose}>
                    <CloseIcon color='#fff' />
                </button>
            </div>
            {!isSuccess && <p className={styles.description}>{t(`brochure.description`)}</p>}
            <MobileTabletBrochureForm isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
            <a className={styles.copyright} href='http://www.virtualvisions.studio/' target='_blank' rel='noopener noreferrer'>
                {t(`copyright`)}
            </a>
        </div>
    )
}

export default MobileTabletBrochure
