// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBar_container__cVWJw {
    display: flex;
    align-items: center;
    position: absolute;
    top: 2vh;
    left: 2vh;
    height: 5%;
    min-height: 50px;
    max-height: -moz-fit-content;
    max-height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    gap: 1%;
    z-index: 10000;
}

.TopBar_logo__16Cv9 svg {
    height: 32px;
    width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/TopBar/TopBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,4BAAuB;IAAvB,uBAAuB;IACvB,uBAAkB;IAAlB,kBAAkB;IAClB,OAAO;IACP,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    position: absolute;\n    top: 2vh;\n    left: 2vh;\n    height: 5%;\n    min-height: 50px;\n    max-height: fit-content;\n    width: fit-content;\n    gap: 1%;\n    z-index: 10000;\n}\n\n.logo svg {\n    height: 32px;\n    width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TopBar_container__cVWJw`,
	"logo": `TopBar_logo__16Cv9`
};
export default ___CSS_LOADER_EXPORT___;
