import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { PageContext } from '../../../contexts'
import { CloseIcon } from '../../../icons/icons'
import MobileTabletCallbackForm from '../../Form/MobileTabletCallbackForm/MobileTabletCallbackForm'
import styles from './MobileTabletRegister.module.css'

const MobileTabletRegister: React.FC<{ unitId?: string | number }> = ({ unitId = '' }) => {
    const { t } = useTranslation()
    const { setActiveButton } = useContext(PageContext)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleClose = () => {
        setActiveButton('mobileMenu')
    }

    if (!isMobile) {
        return null
    }

    return (
        <div onClick={e => e.stopPropagation()} className={styles.container}>
            <div className={styles.header}>
                <span className={styles.title}>{t(`register.title`)}</span>
                <button className={styles.closeButton} onClick={handleClose}>
                    <CloseIcon color='#fff' />
                </button>
            </div>
            {!isSuccess && <p className={styles.description}>{t(`register.description`)}</p>}
            <MobileTabletCallbackForm unitId={unitId} isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
            <a className={styles.copyright} href='http://www.virtualvisions.studio/' target='_blank' rel='noopener noreferrer'>
                {t(`copyright`)}
            </a>
        </div>
    )
}

export default MobileTabletRegister
