import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'

import { useIsMobileView } from '../../../hooks/useIsMobileView'
import { ArrowleftIcon, CrossIcon } from '../../../icons/icons'
import styles from './BackButton.module.css'

const routes: {
    path: string
    previousPath: string
    buttonLabel: string
    availableRoutes: { path: string; label: string }[]
}[] = [
    {
        path: '/city',
        previousPath: '/',
        buttonLabel: 'Home',
        availableRoutes: [],
    },
    {
        path: '/zoomed-city',
        previousPath: '/city',
        buttonLabel: 'Dubai',
        availableRoutes: [
            { path: '/city', label: 'Dubai' },
            { path: '/', label: 'Home' },
        ],
    },
    {
        path: '/masterplan',
        previousPath: '/zoomed-city',
        buttonLabel: 'Silicon Oasis',
        availableRoutes: [
            { path: '/zoomed-city', label: 'Silicon Oasis' },
            { path: '/city', label: 'Dubai' },
            { path: '/', label: 'Home' },
        ],
    },
    {
        path: '/page-360',
        previousPath: '/masterplan',
        buttonLabel: 'Serenova',
        availableRoutes: [
            { path: '/masterplan', label: 'Serenova' },
            { path: '/zoomed-city', label: 'Silicon Oasis' },
            { path: '/city', label: 'Dubai' },
            { path: '/', label: 'Home' },
        ],
    },
    {
        path: '/house-plan',
        previousPath: '/masterplan',
        buttonLabel: 'Serenova',
        availableRoutes: [
            { path: '/masterplan', label: 'Serenova' },
            { path: '/zoomed-city', label: 'Silicon Oasis' },
            { path: '/city', label: 'Dubai' },
            { path: '/', label: 'Home' },
        ],
    },
]

const BackButton: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isMobileView = useIsMobileView()
    const [textToShow, setTextToShow] = useState<string>('button.back')
    const [previousPath, setPreviousPath] = useState<string>('/house-plan')
    const [pathList, setPathList] = useState<{ path: string; label: string }[]>([])
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const hoverDelayRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        routes.forEach(route => {
            if (location.pathname === route.path) {
                setTextToShow(route.buttonLabel)
                setPreviousPath(route.previousPath)
                setPathList(route.availableRoutes)
            }
        })
    }, [location])

    const handleHover = (isEntering: boolean) => {
        if (pathList.length > 0) {
            if (isEntering) {
                if (hoverDelayRef.current) {
                    clearTimeout(hoverDelayRef.current)
                }
                setIsHovered(true)
            } else {
                hoverDelayRef.current = setTimeout(() => {
                    setIsHovered(false)
                }, 200)
            }
        }
    }

    if (isMobileView) {
        return (
            <button onClick={() => navigate(previousPath)}>
                {location.pathname === '/page-360' ? <CrossIcon color='#FFF' /> : <ArrowleftIcon width={20} height={17} />}
            </button>
        )
    }

    const containerVariants = {
        initial: {
            y: -10,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5, // Durée fluide
                ease: 'easeInOut', // Courbe d'easing douce
                delayChildren: 0.2,
                staggerChildren: 0.1,
            },
        },
        exit: {
            y: -10,
            opacity: 0,
            transition: {
                duration: 0.5, // Durée fluide
                ease: 'easeInOut', // Courbe d'easing douce
                staggerChildren: 0.1,
                staggerDirection: -1,
            },
        },
    }

    const itemVariants = {
        initial: {
            opacity: 0,
            x: -10,
        },
        animate: {
            opacity: 1,
            x: 0,
            transition: {
                stiffness: 50,
            },
        },
        exit: {
            opacity: 0,
            x: -10,
            transition: { duration: 0.2 },
        },
    }

    const handleBackButtonClick = () => {
        if (isMobileView) {
            handleHover(!isHovered)
        } else {
            navigate(previousPath)
        }
    }

    return (
        <>
            <motion.button
                className={[styles.container, isHovered ? styles.hovered : ''].join(' ')}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                onClick={handleBackButtonClick}>
                {location.pathname === '/page-360' ? <CrossIcon color='var(--primary-accent)' /> : <ArrowleftIcon width={14.62} height={10.67} />}
                <span className={styles.text}>{textToShow}</span>
            </motion.button>
            <AnimatePresence>
                {isHovered && pathList.length > 0 && (
                    <motion.div
                        onMouseEnter={() => handleHover(true)}
                        onMouseLeave={() => handleHover(false)}
                        className={styles.listContainer}
                        variants={containerVariants}
                        initial='initial'
                        animate='animate'
                        exit='exit'>
                        <span className={styles.listTitle}>back to</span>
                        {pathList.map((element, index) => (
                            <motion.button
                                className={styles.listElement}
                                whileHover={{
                                    paddingLeft: '16px',
                                    backgroundColor: 'var(--primary-accent-02)',
                                }}
                                key={`path-${index}`}
                                variants={itemVariants}
                                onClick={() => navigate(element.path)}>
                                {element.label}
                            </motion.button>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default BackButton
